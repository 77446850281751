import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import dashboard from '../views/dashboard.vue';
import productManage from '../views/ProductManage.vue';
import productStatus from '../views/ProductStatus.vue';
import alarmRecord from '../views/alarmRecord.vue';
import login from '../views/NewLogin.vue';
import program from '../views/program.vue';
import live from '../views/MachineLive.vue';
import productionReport from '../views/productionReport.vue';
import oee from '../views/overalEquipmentEffectiveness.vue';
import monitor from '../views/Monitor.vue';
import monitorManage from '../views/MonitorManage.vue';
import Enterprise from '../views/Admin.vue';
import monitorSetting from '../views/monitorSetting.vue';

Vue.use(VueRouter)

const requireAuth = () => (from, to, next) => {
  if (store.state.accessToken !=null && store.state.accessToken !='null' ){
    return next() // isAuth === true면 페이지 이동
  } else{
    console.log('go login')
    return next({path:'/login'}) // isAuth === false면 다시 로그인 화면으로 이동
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter:requireAuth(),
    component: dashboard,
    meta: {
      menuActive: true,
      showHeader: false,
      scroll: true,
    },
  }, 
  {
    path: '/manage',
    name: 'manage',
    beforeEnter:requireAuth(),
    component: productManage,
    meta: {
      menuActive: true,
      showHeader: true,
      scroll: true,
    },
  }, 
  {
    path: '/status',
    name: 'status',
    beforeEnter:requireAuth(),
    component: productStatus,
    meta: {
      menuActive: true,
      showHeader: false,
      scroll: true,
    },
  }, 
  {
    path: '/alarmRecord',
    name: 'alarmRecord',
    beforeEnter:requireAuth(),
    component: alarmRecord,
    meta: {
      menuActive: true,
      showHeader: false,
      scroll: false,
    },
  }, 
  {
    path:'/logout',
    name:'logout',
    component: login,
    meta: {
      menuActive: false,
      showHeader: false,
      scroll: true,
    },
  
  }, 
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      menuActive: false,
      showHeader: false,
      scroll: true,
    },
  },
  {
    path: '/program',
    name: 'program',
    beforeEnter:requireAuth(),
    component: program,
    meta: {
      menuActive: true,
      showHeader: false,
      scroll: false,
    },
  },
  {
    path: '/live',
    name: 'machinelive',
    beforeEnter:requireAuth(),
    component: live,
    meta: {
      menuActive: true,
      showHeader: false,
      scroll: true,
    },
  },
  {
    path: '/productionReport',
    name: 'productionReport',
    beforeEnter:requireAuth(),
    component: productionReport,
    meta: {
      menuActive: true,
      showHeader: false,
      scroll: false,
    },
  }, 
  {
    path: '/oee',
    name: 'oee',
    beforeEnter:requireAuth(),
    component: oee,
    meta: {
      menuActive: true,
      showHeader: false,
      scroll: false,
    }
  }, 
  {
    path: '/monitor/:mon/:mon_id',
    name: 'monitor',
    component: monitor,
    meta: {
      menuActive: false,
      showHeader: false,
      scroll: true,
    }
  },
  {
    path: '/monitormanage',
    name: 'monitormanage',
    beforeEnter:requireAuth(),
    component: monitorManage,
    meta: {
      menuActive: true,
      showHeader: true,
      scroll: true,
    }
  },
  {
    path: '/admin',
    name: 'admin',
    beforeEnter:requireAuth(),
    component: Enterprise,
    meta: {
      menuActive: true,
      showHeader: true,
      scroll: true,
    }
  },
  {
    path: '/monitorSetting',
    name: 'monitorSetting',
    beforeEnter:requireAuth(),
    component: monitorSetting,
    meta: {
      menuActive: true,
      scroll: false,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  
  routes
})

export default router
