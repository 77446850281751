import http from './http';
import store from '../src/store';
import axios from 'axios';
import { relativeTimeThreshold } from 'moment';
class CncHandler {

  constructor() {
    this.stopCode = ["M0", "M00", "M1", "M01","M61", "M62"]
    this.changeTableCode = ["M61", "M62"]
    this.stopRegx = new RegExp("M([0-9]+)");
    this.MIDS={};
    this.filter=[];
    this.state=Number;
    this.retrystart=false;
    this.reconnect=false;
  }
  requestHandler(fn){
    this.requestHandler=fn;
  }
  midStatusChange(mkey){
    this.midStatusChange = mkey;
  }
  notiChangeValue(data){
    this.notiChangeValue = data;
  }
  setFilter(ff){
    this.filter =ff;
  }
  setCncList(pp){
    this.processList = pp;
  }
  graphReset(fn){
    this.graphReset = fn;
  }
  graphRef(fn){
    this.graphRef = fn;
  }
  graphRefresh(fn){
    this.graphRefresh = fn;
  }
  // toolCount(fn){
  //   this.toolCount=fn;
  //   this.state=2;
  //   console.log(this.toolCount);
  // }
  toolCount(val){
    this.toolCount = val;
  }
  toolReset(val){
    this.toolReset = val;
  }
  checkStopCode(value){
    if (value == null || value ==='') return false
    var mcode = this.stopRegx.exec(value)
    if(mcode == null){
      return false
    }else{
      return this.stopCode.includes(mcode[0])
    }
  }
  checkChangeTableCode(value){
    if (value == null || value ==='') return false
    var mcode = this.stopRegx.exec(value)
    if(mcode == null){
      return false
    }else{
      return this.changeTableCode.includes(mcode[0])
    }
  }
  start(){
    var tt = new Date();
    this.timer = tt.getTime();
    this.interval = setInterval(() => {
      for (var cnc in this.MIDS){
        var machine = this.MIDS[cnc];
        if("ACTIVE" === machine.execution &&  machine.pause===false ){
            machine.remainTime-=1000;
        }
      }
      this.timer += 1000;
    }, 1000);
    this.loadCurrentProcess();
  }
  setCurrentTime(n,vm){
    var diff = n.getTime() - this.timer;
      if(diff > 2000){
        vm.$disconnect();
        this.timer = n.getTime();
        this.retrystart=true;
        this.reconnect=false;
        console.log('disconnect start');
      }else if(this.retrystart){
        if(this.reconnect==true && vm.$store.state.socket.isConnected){
          this.retrystart=false;
          this.loadCurrentProcess();
          this.timer = n.getTime();
          console.log('reconnect finish');
        }else{
          if(vm.$store.state.socket.isConnected==false){
            console.log(vm.$store.state.socket.url)
            if(vm.$store.state.socket.url!==''){
              vm.$connect( vm.$store.state.socket.url);
              this.reconnect=true;
              console.log('reconnect start');
            }
          }else{
            console.log('not disconn')
          }
        }
      }
  }
  stop(){
    clearInterval(this.interval);
		this.graphRefresh = (fn) => {
			this.graphRefresh = fn;
		}
		this.graphReset = (fn) => {
			this.graphReset = fn;
		}
  }
  filterMachine(mkey){
    if(this.filter.length ===0 ){
      return true;
    }else{
      return this.filter.includes(mkey)
    }
  }
  loadCurrentProcess(){
    http.APIS.get('/api/process/list').then(result =>{
     var index=0;
     this.MIDS={};
     this.processList.length=0;
     result.data.forEach(element => {
        if(!this.filterMachine(element.mkey)){
         return;
        }
        var sindex = this.filter.indexOf(element.mkey);
        if(sindex < 0){
          sindex=index++;
        }
        var process ={};
        process.index = sindex;
        process.processTime = element.active_time ;
        process.processFullTime = element.active ;
        process.waitTime = element.wait;
        process.process = element.process;
        process.part_count = element.process_count;
        process.plan_count = element.plan_count;
        process.startymdt = element.start_ymdt;
        process.mid = element.mid;
        process.mkey = element.mkey;
        process.machineNo = element.machine_no;
        process.lot = element.lot;
        process.pause=false;
        process.changeTablePause=false;
        process.pauseMcode='';
        process.execution=null;
        process.msg=null;
        process.mode=null;
        process.live = {Xload:0,mode:'',path_feedrate:0,position:{x:[],y:[],z:[]}};
        process.modal=[];
        process.currentBlock;
        process.curPosition={x:0,y:0,z:0};
        process.resetGraph=false;
				process.debug_blob_message='';
        var remainCount = process.plan_count - process.part_count;
        if(remainCount > 0){
          process.estLotFinishTime = (process.processFullTime + process.waitTime ) * remainCount;
        }
        process.remainTime=0;
        this.MIDS[process.mkey] = process;
        this.processList.push(process);   
      });
      this.processList.sort( (a,b)=> a.index - b.index )
      this.loadEdgeStatus();
    }).catch(error=>{
        console.log(error)
    })
  }

  loadEdgeStatus(){
    http.APIS.post('/api/cloud/installedTransmitters',{}).then(result =>{
      store.state.edges=result.data.data;
      result.data.data.forEach(item=>{
        http.EDGE.post('/api/edge/edge_machine_stat',{transmitter:item.id}).then(result =>{
        })
      })
    })
  }
  getProcessData(mkey){
    return this.MIDS[mkey];
  }
  processExtraPartCount(data){
    var d = data.split("|");
    //var mid = d[3];
    var start=parseInt(d[7]);
    var activeFull =parseInt( d[9]);
    var wait =parseInt( d[10]);
    var active =parseInt( d[11]);
    var partCount = parseInt(d[5]);
    var planCount = parseInt(d[6]);
    var mkey = parseInt(d[13]);
    var remainCount = planCount - partCount;
    if(!this.filterMachine(mkey)){
      return;
    }
    this.MIDS[mkey].waitTime = wait;
    if(remainCount > 0){
      this.MIDS[mkey].estLotFinishTime = (activeFull + wait ) * remainCount;
    }else{
      this.MIDS[mkey].estLotFinishTime =0;
    }
    if(partCount != this.MIDS[mkey].part_count ){
      this.MIDS[mkey].partCountChange=true;
      this.MIDS[mkey].partCountChangeTime=(new Date()).getTime();
    }
    this.MIDS[mkey].part_count = partCount;
    this.MIDS[mkey].plan_count = planCount;
    this.MIDS[mkey].processTime = active;
    this.MIDS[mkey].remainTime =  0;
    this.MIDS[mkey].pause=false;
    this.MIDS[mkey].pauseMcode=''

    http.APIS.get('/api/process/LotEndtime/'+this.MIDS[mkey].lot,{}).then(async result =>{
      if(result.data[0].lot_endtime){
        this.MIDS[mkey].lot_endtime = result.data[0].lot_endtime;
        this.MIDS[mkey].lot_endtime = this.MIDS[mkey].lot_endtime.replace('T',' ').substring(0,19);
      }  
    })
    if(this.state==1){
      this.graphRef(mkey,start,wait);
    }
    if(this.state==2){
      this.toolCount(mkey,partCount);
    }
  }
  processExtraAlarm(data){
    // var data= ('EXTRA|ALARM|1626931509540|YHS|Lynx220LC|OT0500 Ztravel (Z)+ 오버트러블베이비         (             1) 4|4|1')
    var d = data.split("|");
    var alarm=  d[5].split(" ");
    var code =alarm[0];
    var mkey = d[6];
    var time = d[2];
    const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    if(!korean.test(d[5])){
      http.get('/api/message/'+code).then(r=>{
        if(r.data.length!=0){
          this.MIDS[mkey].msg = alarm[1]+alarm[2]+r.data[0].msg;
        }else{
          this.MIDS[mkey].msg = '지정 되지 않은 알람입니다. 기계를 확인해주세요';  
        }  
      })
    }else{
      if(d[5]!=undefined){
        this.MIDS[mkey].msg = d[5];
      }
    }
  }
  processExtraMessage(data){
    // var data = ('EXTRA|MESSAGE|1626931509540|YHS|Lynx220LC|REF.RETURN COMMANDED AT REF.POSITION|19|1')
    var d= data.split("|");
    var message = d[5];
    var mkey = d[6];
    if(message!=undefined){
      this.MIDS[mkey].msg = message;
    }
    
  }
  processExtraNoti(data){
    var d = data.split("|");
    var a = new Date(d[5]).getTime();
    //var mid = d[3];
    var mkey = parseInt(d[4]);
    if(!this.filterMachine(mkey)){
      return;
    }
    var live={} ;
    for(var i2 = 6 ; i2 < d.length;i2=i2+2){
        live[ d[i2] ] = d[i2+1];
    }
    var execution = live.execution;
    var estop = live.estop;
    if(estop === "TRIGGERED"){
      execution = "POWER_OFF";
    }
    
    if( this.checkStopCode(live.block)){
      this.MIDS[mkey].pause=true;
      this.MIDS[mkey].pauseMcode=live.block;
      if( this.checkChangeTableCode(live.block)){
        this.MIDS[mkey].changeTablePause=true;
      }
    }else {
      this.MIDS[mkey].pause=false;
      this.MIDS[mkey].changeTablePause=false;
      this.MIDS[mkey].pauseMcode=''
    }
    if(execution=="ACTIVE" && this.MIDS[mkey].partCountChange){
      if(this.MIDS[mkey].partCountChangeTime +1000 <  a ){
         this.MIDS[mkey].remainTime = this.MIDS[mkey].processTime;
         this.MIDS[mkey].partCountChange=false;
         this.MIDS[mkey].resetGraph=true;
      }
    }
    if(live.execution != undefined){
      this.MIDS[mkey].execution = execution;
    }
    if(live.mode !==undefined){
      this.MIDS[mkey].mode = live.mode;
    }
    if(live.program_comment!==undefined){
       this.MIDS[mkey].process = live.program_comment.replace("%","").replace(" ","");
    }
    if(d.indexOf('RESET')!=-1){
      this.MIDS[mkey].msg= null;
    }
  }
  listenWebSocketResponse(mkey){
    return (data) => {
      if(data.data.constructor.name === 'Blob'){
				data.data.text().then(text=>{
					this.MIDS[mkey].debug_blob_message = text;
					if(text.indexOf("EXTRA|PART_COUNT")==0){
						this.processExtraPartCount(text);
					}else if(text.indexOf("EXTRA|NOTI")==0){
						this.processExtraNoti(text);
					}else if(text.indexOf("EXTRA|MESSAGE")==0){
						this.processExtraMessage(text);
					}else if(text.indexOf("EXTRA|ALARM")==0){
						this.processExtraAlarm(text);
					}else{
						this.processCNCData(text);
					}
        })
      }else{
        var resp = JSON.parse(data.data)
        if(resp.response ==="EDGE_MACHINES_STAT"){
            this.processEdgeMachineStat(resp.data);
        }else if(resp.response ==="RELOAD_PAGE"){
          location.reload(true);
        }else if(resp.response=="CALL_FUNC_RESULT"){
          this.requestHandler(resp);
        }else if(resp.response=="BROADCAST"){
          if(resp.data.type == 'toolChange'){
            if(this.toolReset!=undefined){
              var data1=resp.data.reset_data;
              this.toolReset(data1.index,data1.xval,data1.zval,data1.set_count_val);
            }
          }else if(resp.data.type == 'reload'){
            if(resp.data.mkey==mkey){
              location.reload(true);
            }
          }else if(resp.data.type == 'notice'){
            if(this.notiChangeValue!=undefined){
              this.notiChangeValue(resp.data);
            } 
          }else if(resp.data.type == 'midStatus'){
            this.midStatusChange(resp.data.mkey);
          }
        }
      }
    }
  }
  processCNCData(data){
    var d = data.split("|");
    var mkey = parseInt(d[2]);
    var start= d[3];
    if(!this.filterMachine(mkey)){
      return;
    }
    for(var i = 4 ; i < d.length;i=i+2){
        if(d[i]==""){
          console.log(data);
        }
        this.MIDS[mkey].live[d[i]] = d[i+1];
        if(d[i]==='block'){
          this.MIDS[mkey].modal.push({time:d[3],block:d[i+1]});
          this.MIDS[mkey].currentBlock = d[i+1].trim();
          if(this.MIDS[mkey].modal.length > 12){
            this.MIDS[mkey].modal.shift();
          }
          if(this.MIDS[mkey].resetGraph){
            this.MIDS[mkey].live.position.x.length=0;
            this.MIDS[mkey].live.position.z.length=0;
            this.MIDS[mkey].live.position.y.length=0;
            this.MIDS[mkey].modal.length=0;
            this.MIDS[mkey].resetGraph=false;
            if(this.graphReset!==undefined){
              this.graphReset(mkey);
            }
          }
        }else if(d[i] =="path_position"){
          var xyz = d[i+1].split(" ");
          var x= parseFloat(xyz[0]);
          var y= parseFloat(xyz[1]);
          var z= parseFloat(xyz[2]);

          //if(z<50){
            // this.MIDS[mkey].curPosition.x=x;
            // this.MIDS[mkey].curPosition.y=y;
            // this.MIDS[mkey].curPosition.z=z;
            this.MIDS[mkey].live.position.x.push(x);
            this.MIDS[mkey].live.position.z.push(z);
            
            // this.MIDS[mkey].live.path_feedrate=feed;
            // this.MIDS[mkey].live.xload=xload;
            // this.MIDS[mkey].live.zload=zload;
            // this.MIDS[mkey].live.S1load=sload;
            // this.MIDS[mkey].live.S1speed=speed;
            // if(this.MIDS[mkey.live.yload]){
            //   this.MIDS[mkey].live.yload=yload;
            // }

            if(this.graphRefresh!==undefined && (typeof this.graphRefresh) === 'function'){
              this.graphRefresh(mkey,start,this.MIDS[mkey].live,x,y,z);
              //this.drawFn(mkey,x,y,z);
            }
          //}
        }
    }
  }
  processEdgeMachineStat(data){
    data.forEach( data=>{
            var mid = data.Mid;
            var mkey = parseInt(data.Id);
            if(!this.filterMachine(mkey)){
              return;
            }
            if(this.MIDS[mkey]==undefined){
              var process ={};
              var sindex = this.filter.indexOf(mkey);
              if(sindex < 0){
                sindex=this.processList.length+1;
              }
              process.index = sindex;
              process.processTime = 0 ;
              process.processFullTime = 0 ;
              process.waitTime = 0;
              process.process = '';
              process.part_count = 0;
              process.plan_count = 0;
              process.startymdt = 0;
              process.mid = mid;
              process.mkey = mkey;
              process.lot = 0;
              process.pause=false;
              process.changeTablePause=false;
              process.pauseMcode='';
              process.execution=null;
              process.mode=null;
              process.live = {Xload:0,mode:'',path_feedrate:0,position:{x:[],y:[],z:[]}};
              process.modal=[];
              process.currentBlock;
              process.curPosition={x:0,y:0,z:0};
              process.resetGraph=false;
              var remainCount = process.plan_count - process.part_count;
              if(remainCount > 0){
                process.estLotFinishTime = (process.processFullTime + process.waitTime) * remainCount;
              }
              process.remainTime=0;
              this.MIDS[process.mkey] = process;
              this.processList.push(process);
            }
            this.processList.sort( (a,b)=> a.index - b.index )
            var execution = data.Execution;
            var partCount = data.PartCount;
            var planCount = data.PlanCount;
            var activeStartTime= data.ActiveTime;
            var partCountChangeTime= data.CountTime;

            var workTime= data.WorkTime;
            var tactiveTime = data.TActiveTime;
            var block = data.Block;

            var sendByte = data.SendByte;
            if(data.Estop == "TRIGGERED"){
              execution = "TRIGGERED";
            }
            if(data.Power==false){
              execution = "POWER_OFF";
            }
            var _remainCount = planCount - partCount;
            if(_remainCount > 0){
                this.MIDS[mkey].estLotFinishTime  = (this.MIDS[mkey].processFullTime + this.MIDS[mkey].waitTime) * _remainCount;
            }else{
              this.MIDS[mkey].estLotFinishTime=0;
            }
            var lastPart = this.MIDS[mkey].part_count;
            this.MIDS[mkey].live.estop = data.Estop;
            this.MIDS[mkey].activeStartTime = activeStartTime;
            this.MIDS[mkey].partCountChangeTime = partCountChangeTime;
            this.MIDS[mkey].process = block;
            this.MIDS[mkey].execution = execution;
            this.MIDS[mkey].message = data.Message;
            this.MIDS[mkey].mode = data.Data.mode;
            this.MIDS[mkey].part_count = partCount;
            this.MIDS[mkey].plan_count = planCount;
            var dt = new Date();
            if(this.checkStopCode( data.Data.block )){
              this.MIDS[mkey].pause = true;
              this.MIDS[mkey].pauseMcode=data.Data.block;
              if(this.checkChangeTableCode( data.Data.block )){
                this.MIDS[mkey].changeTablePause = true;
              }
            }
            if(workTime > 0){
              if(tactiveTime > 0){
                this.MIDS[mkey].remainTime =  this.MIDS[mkey].processTime  -   workTime  - (dt.getTime() - tactiveTime );
              }else{
                this.MIDS[mkey].remainTime =  this.MIDS[mkey].processTime - workTime;
              }
            }else{
              this.MIDS[mkey].remainTime=this.MIDS[mkey].processTime - (dt.getTime() - activeStartTime );
            }
            if(partCountChangeTime >  activeStartTime || lastPart < partCount ){
              this.MIDS[mkey].partCountChange=true;
            }else{
              this.MIDS[mkey].partCountChange=false;
            }
            this.MIDS[mkey].sendbyte = sendByte;
    });
  }
}

export default new CncHandler;

