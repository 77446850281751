<template>
   <div class="product_manage">
    <!-- bottom -->
    <div class="product_manage__content" ref="bottom">
      <!-- left -->
      <div class="content__left block">
        <!-- header -->
        <div class="product_manage__header" ref="header">
          <div 
            class="header__machine"
            :class="{ 'null': mdata.execution == null}"
            :style="'background-color: '+ getStatusColor(mdata.execution)"
          >
            <p class="header__machine_execution">{{executionForm(mdata.execution)}}</p>
            <p class="header__machine_number">{{ mdata.machineNo }}</p>
          </div>
          <div class="header__info">
            <div class="header__mid">
              <p class="header__mid_text">{{ mdata.mid }}</p>
            </div>
            <p class="header__process block">
							{{ mdata.process }} ({{ mdata.part_count }})
							<button v-on:click="toggle_websocket_message_view">🔮</button>
							</p>
          </div>
        </div>
        <!-- gauge -->
				<div v-if="view_websocket_message" style="font-size: .8rem; color: blue;">{{ mdata.debug_blob_message }}</div>
        <div class="graph_content">
          <div class="graph_content__inner">
            <div class="speed graph">
              <p class="graph__title">
                Spindle<br>Speed
                <br><span class="unit">(rpm)</span>
              </p>
							<div id="spindle_speed_grp"></div>
            </div>
            <div class="feed graph">
              <p class="graph__title">
                Feed
                <br><span class="unit">(mm/s)</span>
              </p>
              <div id="feed_grp"></div>
            </div>
            <div class="sload graph">
              <p class="graph__title">
                Spindle<br>Load
                <br><span class="unit">(%)</span>
              </p>
              <div id="spindle_load_grp"></div>
            </div>
            <div class="x graph">
              <p class="graph__title">
                X-axle<br>Load
                <br><span class="unit">(%)</span>
              </p>
							<div id="x_axle_load_grp"></div>
            </div>
            <div class="z graph">
              <p class="graph__title">
                Z-axle<br>Load
                <br><span class="unit">(%)</span>
              </p>
							<div id="z_axle_load_grp"></div>
            </div>
            <div class="y graph">
              <p class="graph__title">
                Y-axle<br>Load
                <br><span class="unit">(%)</span>
              </p>
							<div id="y_axle_load_grp"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- right -->
      <div id="bottom_right" class="content__right block">
        <div class="content__right_inner">
          <p class="graph__title v1__title">Tool Path 그래프</p>
          <div class="v1">
						<div id="vgrp" :style="'min-width: 300px; width: ' + 28 + 'vw; height:' + 26 + 'vh;'"></div>            
          </div>
          <div class="gcode">
            <v-data-table
              :items="mdata.modal"  
              :headers="gcodeHeaders"
              dense
              :options="{itemsPerPage:5}"
              hide-default-header
              hide-default-footer
            >
              <template slot="no-data">
                <div class="no-data">데이터가 없습니다</div>
              </template>
            </v-data-table>
          </div>
          <ul class="message">
            <li class="graph__title gcode__title">알람 <span class="amp">&amp;</span> 메시지</li>
            <li class="item" v-for="(m, index) in message" :key="m.date + 'msg' + index">
              <p class="date">
                <v-icon size="18" class="alert_icon">$alertTri</v-icon>
                {{ dateform(m.date) }}
              </p>
              <p class="text">{{ m.message.replace(/[0-9]/g, "") }}</p>
            </li>
          </ul>
          <div class="memo">
            <div class="graph__title memo__title graph__title-icon">
              <p class="text">메모</p>
              <v-btn 
                fab depressed text x-small
                class="write_btn"
                @click="memoWrite"
              >
                <v-icon size="20" v-if="!memoDisabled">$modify</v-icon>
                <v-icon size="20" v-if="memoDisabled">mdi-check</v-icon>
              </v-btn>
            </div>
            <textarea 
              class="textarea"
              v-model="memo"
              :readonly="!memoDisabled"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import moment from 'moment';
import VueHead from 'vue-head';
import PlotlyOrigin from 'plotly.js-dist-min';
import _ from 'lodash';

Vue.use(VueHead);
  export default {
    name: 'productManage',
    data() {
      return {
        processList:[],
				graph_data_buffer: [],
				is_graph_start_time_reset: false,
        loadCNCData: false,
        mkey:0,//this.$store.state.selectedCnc.mkey,
        mdata:{},
        memo:'',
        websocket_message: '',
				view_websocket_message: false,
        //그래프 data와 layout
				plotly_data_template: [
					{
						x: [],
						y: [],
						name: '현재가공', 
						type: 'scatter',
						marker: { size: 1,  color:'#3768f3' },
						fill: 'tozeroy',
						fillcolor: 'rgba(55, 104, 243, 0.3)',
						line: { color: '#3768f3',},
						opacity: 1
					},
					{
						x: [],
						y: [],
						name: '이전가공',
						type: 'scatter',
						marker: { size: 1, color:'#838383' }, 
						opacity: 0.7
					},
					{
						value: 0,
						type: 'indicator',
						mode: 'gauge+number',
						domain: { x: [0.87, 0.97], y: [0, 0.6] }
					}
				],
				plotly_layout_template: {
					autosize: true,
					hovermode: 'x',
					grid: {
						rows: 1,
						columns: 2
					},
					margin: { l: 40, r: 20, b: 25, t: 20, pad: 4 },
					xaxis: {
						type:'date',
						tickformat: '%M:%S.%2f',
						domain: [0, 0.83]
					},
					showlegend: false
				},
				plotly_config_template: {
					displayModeBar: false,
					responsive: true
				},
				plotly_range_data: {
					spindle_speed: {
						range: [0, 1200],
						steps: [
							{ range: [0, 400], color: '#55BF3B' },
							{ range: [400, 800], color: '#DDDF0D' },
							{ range: [800, 1200], color: '#D9484B' }
						]
					},
					feed: {
						range: [0, 15000],
						steps: [
							{ range: [0, 5000], color: '#55BF3B' },
							{ range: [5000, 10000], color: '#DDDF0D' },
							{ range: [10000, 15000], color: '#D9484B' }
					]
					},
					spindle_load: {
						range: [0, 150],
						steps: [
							{ range: [0, 50], color: '#55BF3B' },
							{ range: [50, 100], color: '#DDDF0D' },
							{ range: [100, 150], color: '#D9484B' }
						]
					},
					x_axle_load: {
						range: [0, 150],
						steps: [
							{ range: [0, 50], color: '#55BF3B' },
							{ range: [50, 100], color: '#DDDF0D' },
							{ range: [100, 150], color: '#D9484B' }
						]
					},
					z_axle_load: {
						range: [0, 150],
						steps: [
							{ range: [0, 50], color: '#55BF3B' },
							{ range: [50, 100], color: '#DDDF0D' },
							{ range: [100, 150], color: '#D9484B' }
						]
					},
					y_axle_load: {
						range: [0, 150],
						steps: [
							{ range: [0, 50], color: '#55BF3B' },
							{ range: [50, 100], color: '#DDDF0D' },
							{ range: [100, 150], color: '#D9484B' }
						]
					}
				},
				plotly_data: {
					spindle_speed: {},
					feed: {},
					spindle_load: {},
					x_axle_load: {},
					z_axle_load: {},
					y_axle_load: {}
				},
        timer: moment().format('YYYY-MM-DD hh:mm:ss'),
        message: [],
        memoDisabled: false,
				v1_gd: undefined,
        v1Data: [{
          x: [],
          y: [],
          z: [],
          text:[],
          color:[],
          type:"scatter",
          mode:"lines+markers",
          showlegend: false,
          marker: {
            size: 4,
            color:[],
            line:{
              color:'gray'
            }
          },
          opacity: 1
        }],
        v1Layout: { // 오른쪽 그래프
          //title: "xyz plot",
          paper_bgcolor:'#747980',
          plot_bgcolor:'#747980',
          autosize: true,
          margin: { l: 30, r: 30, b: 30, t: 30,},
          xaxis: {
            title: {
              text: 'Z Axis',
              font: {
                family: 'Courier New, monospace',
                size: 12,
                color: '#7f7f7f'
              }
            },
           },
          yaxis: {
            title: {
              text: 'X Axis',
              font: {
                family: 'Courier New, monospace',
                size: 12,
                color: '#7f7f7f'
              }
            }
          },
          // config: {
          //   responsive: true,
          // }
        },
        memoid:Number,
        gcodeData: [],
        gcodeHeaders: [
          { text: 'Gcode', value: 'block', align: 'right' },
        ],
        colorchip: {
          gray: '#8c8c8c',
          traffic_red: '#F56713',
          traffic_yellow: '#F5B117',
          traffic_green: '#6BB236',
          progress_gray: '#B2B2B2',
        }
      };
    },
    head: {
      meta: [
        { 'http-equiv': 'Pragma', content: 'no-cache' },
        { 'http-equiv': 'Expires', content: '0' },
        { 'http-equiv': 'Cache-Control', content: 'no-cache' }
      ]
    },
    methods: {
			toggle_websocket_message_view() {
				this.view_websocket_message = !this.view_websocket_message;
			},
      executionForm(v){
        if(v==null){
          v = `Network\
          Error`;
        }
        return v;
      },
			// 완료된 가공 데이터를 이전가공 데이터로 넣고, 새로 가공될 데이터는 초기화 해준다.
			add_before_scatter_data_and_initialize_new_scatter_data() {
				for (const graph_key in this.plotly_data) {
					const plotly_data = this.plotly_data[graph_key];
					plotly_data.data[1].x = plotly_data.data[0].x.splice(0);
					plotly_data.data[1].y = plotly_data.data[0].y.splice(0);
				}
			},
			// 좌측 plotly 데이터 업데이트 메소드
			update_plotly_data(key, x, y) {
				const plotly_data = this.plotly_data[key];
				plotly_data.data[0].x.push(x);
				plotly_data.data[0].y.push(y);
			},
			// 좌측 plotly 업데이트 메소드
			update_plotly(key, x, y) {
				this.update_plotly_data(key, x, y);
				const plotly_data = this.plotly_data[key];
				const update = {
					x: [plotly_data.data[0].x, plotly_data.data[1].x],
					y: [plotly_data.data[0].y, plotly_data.data[1].y],
					value: [undefined, undefined, y],
					line: [plotly_data.data[0].line, plotly_data.data[1].line],

				}
				PlotlyOrigin.restyle(plotly_data.gd, update);
			},
			// 우측 V1 데이터 업데이트 메소드
			update_v1_plotly_data(x, y, z) {
				this.v1Data[0].x.push(x);
				this.v1Data[0].y.push(y);
				this.v1Data[0].z.push(z);
			},
      getalarm(){
        this.$http.DT.get('/manage/'+this.mkey).then(r=>{
         this.message=r.data;
        })
      },
      getmemo(){
        this.$http.DT.get('/memo/m/'+this.mkey).then(r=>{
          if(!r.data.length==0){
           this.memo=r.data[0].comment;
          }
        })
      },
      dateform(val){
        val= val.replace('T',' ').substr(0,19);
        // val= moment(val).format('YYYY-MM-DD HH:mm:ss');
        return val;
      },
      memoWrite() {
        if( this.memoDisabled ) {
          this.$http.DT.post('/memo/m',{memo:this.memo,mid:this.mkey}).then(r=>{
          })
          this.memoDisabled = false;
        } else {
          this.memoDisabled = true;
        }
      },
      getStatusColor(exec) {
        if( exec == 'POWER_OFF' ) {
          return this.colorchip.gray;
        } else if( exec == 'ACTIVE' ) {
          return this.colorchip.traffic_green;
        } else if( exec == 'STOPPED') {
          return this.colorchip.traffic_yellow;
        } else if ( exec == null ) {
          return this.colorchip.traffic_red;
        }
        return this.colorchip.traffic_green;
      },
			// 화면 좌측 plotly 데이터 정리 및 그래프 생성하는 메소드
			init_plotly() {
				for (const plotly_key in this.plotly_data) {
					const plotly_data = this.plotly_data[plotly_key];
					plotly_data.gd = document.getElementById(`${plotly_key}_grp`);
					plotly_data.layout = _.cloneDeep(this.plotly_layout_template);
					plotly_data.layout.yaxis = {
						range: this.plotly_range_data[plotly_key].range
					}
					plotly_data.data = _.cloneDeep(this.plotly_data_template);
					plotly_data.data[2].gauge = {
						axis: { range: this.plotly_range_data[plotly_key].range },
						steps: this.plotly_range_data[plotly_key].steps
					}
					plotly_data.config = this.plotly_config_template;

					PlotlyOrigin.react(plotly_data.gd, plotly_data.data, plotly_data.layout, plotly_data.config);
				}
			}
    },
    mounted(){
			// 좌측 그래프 생성
			this.init_plotly();

			// v1 graph (tool path graph) 는 별개로 처리해준다.
			this.v1_gd = document.getElementById('vgrp');
			PlotlyOrigin.react(this.v1_gd, this.v1Data, this.v1Layout, {displayModeBar: false});

      this.mkey=this.$store.state.selectedCnc.mkey;
      this.$cnc.setCncList(this.processList);
      this.$cnc.start();
      this.$connect(this.$store.state.websocket_url+this.$store.state.sender+'?ent='+this.$store.state.enterprise+'&view=mid:'+this.mkey);
      var intval = setInterval(()=>{
        if(this.$cnc.getProcessData(this.mkey) !==undefined){
          this.$options.sockets.onmessage = this.$cnc.listenWebSocketResponse(this.mkey);
          this.getmemo();
          this.getalarm();
          clearInterval(intval);
        }
        
      },100);
      this.$cnc.graphRefresh((mkey,start,live,x,y,z) => {
				const start_time = this.mdata.activeStartTime;
				if (!start_time) {
					return;
				}
				if (this.is_graph_start_time_reset) {
					// 그래프가 리셋중이면 데이터를 일단 버퍼에 넣어준다.
					this.graph_data_buffer.push({
						start: start,
						live: live,
						x: x,
						y: y,
						z: z
					});
					return;
				}
				if (this.graph_data_buffer.length) {
					this.graph_data_buffer.forEach((graph_data) => {
						const n = new Date(graph_data.start);
						const elap = n.getTime() - start_time;
						n.setTime(elap);
						this.update_v1_plotly_data(graph_data.z, graph_data.x, graph_data.y);
						const live = graph_data.live;
						const spindle_speed = live.S1speed ? live.S1speed : live.S2speed;
						this.update_plotly_data('spindle_speed', n, spindle_speed);
						this.update_plotly_data('feed', n, live.path_feedrate);
						this.update_plotly_data('spindle_load', n, live.S1load);
						this.update_plotly_data('x_axle_load', n, live.Xload);
						this.update_plotly_data('z_axle_load', n, live.Zload);
						if (live.Yload) {
							this.update_plotly_data('y_axle_load', n, live.Yload);
						}
					});
					this.graph_data_buffer = [];
				}
				this.update_v1_plotly_data(z, x, y);
				PlotlyOrigin.restyle(this.v1_gd, {y: [this.v1Data[0].y], x: [this.v1Data[0].x], z: [this.v1Data[0].z]});

				const n = new Date(start);
				const elap = n.getTime() - start_time;
				n.setTime(elap);
				start = n;

				const spindle_speed = live.S1speed ? live.S1speed : live.S2speed;
				this.update_plotly('spindle_speed', start, spindle_speed);
				this.update_plotly('feed', start, live.path_feedrate);
				this.update_plotly('spindle_load', start, live.S1load);
				this.update_plotly('x_axle_load', start, live.Xload);
				this.update_plotly('z_axle_load', start, live.Zload);
				if (live.Yload) {
					this.update_plotly('y_axle_load', start, live.Yload);
				}
      });
      this.$cnc.graphReset(() => {
				this.is_graph_start_time_reset = true;
				this.v1Data[0].x.length = 0;
				this.v1Data[0].y.length = 0;
				this.v1Data[0].z.length = 0;
				this.add_before_scatter_data_and_initialize_new_scatter_data();
				this.$cnc.loadEdgeStatus();
      });
    },
    destroyed(){
			this.$disconnect();
      this.$cnc.stop();
    },
    watch:{
      processList:function(a){
        for(let i=0; i<this.processList.length; i++){
          if(this.mkey == this.processList[i].mkey){
            this.mdata=this.processList[i];
          }
        }
				this.is_graph_start_time_reset = false;
      },
    },

  }
</script>
<style scoped lang="scss">
@import "~@/assets/css/global.scss";

  * {
    font-family: $scd !important;
    font-weight: 300;
  }

  .product_manage {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    overflow: hidden;

    > div {
      width: 100%;
      height: 100%;
      font-size: 20px;
    }

    &__content {
      @extend .flex_row;

      overflow-y: hidden;
      justify-content: stretch;
      background-color: white;

      .block {
        height: 100%;
      }

      .content__left {
        @extend .flex_column;

        justify-content: stretch;
        flex: 1 1 auto;

        .product_manage__header {
          @extend .flex_row;

          width: 100%;
          flex: 0 0 auto;
          min-height: 80px;
          align-items: flex-start;
          padding-left: map-get($vw, 40);
          background-color: white;

          .header__machine {
            @extend .flex_column;

            justify-content: center;
            min-width: map-get($vw, 105);
            min-height: map-get($vw, 100);
            height: 100%;
            padding: map-get($vw, 16);
            margin-right: map-get($vw, 48);
            background: green;

            &.null {
              width: 85px;

              .header__machine_execution {
                width: 80%;
                line-height: 1;
              }
            }

            &_execution {
              text-align: center;
              font-size: map-get($vw, 14);
              letter-spacing: 0.2px;
              font-weight: 400;
              color: white;
            }
            &_number {
              font-size: map-get($vw, 40);
              line-height: 0.7;
              font-weight: 600;
              padding-top: 6px;
              margin-top: 6px;
              text-align: center;
              color: white;
            }
          }
          .header__info {
            @extend .flex_row;

            align-self: flex-end;
            align-items: flex-end;
            padding-bottom: map-get($vw, 12);
            background: unset !important;

            .header__mid {
              font-size: map-get($vw, 40);
              margin-right: 16px;
              padding-bottom: 2px;

              &_text { 
                font-weight: 500;
                padding-top: 3px;
                line-height: 1;
              }
            }
            .header__process {
              font-size: map-get($vw, 24);
              line-height: 1;
              padding-bottom: 5px;
            }
          }
        }
        .graph_content {
          width: 100%;
          flex: 1 1 0;
          padding: map-get($vw, 20) map-get($vw, 40);
          overflow: hidden;

          &__inner {
            @extend .flex_column;

            grid-template-rows: repeat(6, 1fr);
            width: 100%;
            height: 100%;
            justify-content: stretch;
            overflow: hidden;
          }

          .graph {
            display: grid;
            grid-template-columns: auto 1fr auto;
            width: 100%;
            // flex: 1 1 16.666%;
            flex: 1 1 0;

            .graph__title {
							margin-top: 30px;
              width: map-get($vw, 105);
              font-size: map-get($vw, 21);

              .unit {
                font-size: 12px;
                margin-top: 6px;
              }
            }
            .graph_gauge {
              overflow-y: hidden;

              > div {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }

      }

      .content__right {
        flex: 0 0 auto;

        &_inner {
          @extend .flex_column;

          width: 100%;
          height: 100%;
          padding: map-get($vw, 50) map-get($vw, 36) map-get($vw, 25);
          background-color: #ededed;

          > div {
            width: 100%;
          }
        }

        .graph__title {
          width: 100%;
          font-size: map-get($vw, 21);
          font-weight: 500;

          &.graph__title-icon {
            @extend .flex_row;

            justify-content: space-between;
          }

          .text {
            font-size: inherit;
            font-weight: inherit;
          }

          &.v1__title {
            text-align: right;
            font-size: map-get($vw, 24);
            margin-bottom: map-get($vw, 21);
          }
          &.gcode__title {
            border-bottom : 1.2px solid rgba(0, 0, 0, 0.1);
          }
        }

        .v1 {
          width: 100%;
          flex: 0 0 0;
        }

        .gcode {
          flex: 0 0 atuo;
          border-bottom: 1px solid black;
          padding: 4px 0 4px;
          margin-bottom: map-get($vw, 32);

          ::v-deep .v-data-table {
            background-color: unset !important;

            td {
              height: map-get($vw, 16);
              border: 0 !important;
              padding: 0;
            }          
          }
        }

        .message {
          width: 100%;
          flex: 0 0 atuo;
          font-size: map-get($vw, 14);
          border-bottom: 0;
          margin-bottom: map-get($vw, 40);

          .amp {
            font-family: sans-serif !important;
            font-weight: bold;
          }

          .item {
            @extend .flex_row;

            justify-content: stretch;
            padding: 4px 0;
            font-size: map-get($vw, 14);
            border-bottom: 1.2px solid rgba(0, 0, 0, 0.1);
          }
          .date {
            flex: 0 0 38%;
            padding: 0 6px 0 0;

            ::v-deep .alert_icon {
              margin-right: 3px;

              .cls-1 { fill: #949494 !important; }
            }
          }
          .text {
            flex: 1 1 auto;
            word-break: break-word;
          };
        }

        .memo {
          @extend .flex_column;

          justify-content: justify;
          flex: 1 1 auto;
          justify-self: flex-end;
          margin-top: auto;
          padding: map-get($vw, 12);
          background-color: #D5D5D5;

          .memo__title {
            @extend .flex_row;

            width: 100%;
            flex: 0 0 auto;
            margin-bottom: 8px;
          }
          ::v-deep .write_btn {
            width: 36px !important;
            height: 36px !important;
          }

          .textarea {
            flex: 1 1 auto;
            width: 100%;
            height: 100%;
            border-bottom: 1px solid gray;
            font-size: map-get($vw, 15);

            &:focus {
              outline: 0;
              border-bottom: 2px solid cornflowerblue;
            }
          }
        }
      }
    }
  }
</style>